<template>
  <div class="d-flex gap-4 flex-wrap justify-content-center">
    <div
      v-for="information in informations"
      :key="information.title"
      :class="['border border-2 col-5 d-flex align-items-center justify-content-center rounded', information.index % 2 === 0 ? 'bg-primary' : 'border-primary ']"
      :style="`height: ${device === 'mobile' ? 20 : 20}vh;`"
    >
      <div :class="['gap-2 d-flex flex-column align-items-center justify-content-center', device === 'mobile' ? 'p-2 pt-3' : 'p-5']">
        <i
          :class="[information.icon, 'fw-bold', information.index % 2 === 0 ? 'text-white' : 'text-primary']"
          :style="`font-size: ${device === 'mobile' || 'tablet' ? '24' : '50'}px;`"
        />
        <span :class="['fw-bold text-center fs-6 fs-xxl-4', information.index % 2 === 0 ? 'text-white' : 'text-dark']">{{ $t(`domains.ects.program.${information.title}`) }}</span>
        <span :class="['fw-bold text-center fs-7 fs-xxl-5', information.index % 2 === 0 ? 'text-white' : 'text-dark']">{{ information.information ? (information.index === 1 ? `${information.information} ${ $t('common.years')}` : information.information ) : $t('common.no-data') }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'
import { formatProgramInformation } from '../utils'
import { computed } from 'vue'
import { deviceType } from '@/shared/utils'

const store = useEcts()
const device = deviceType()
const { program } = storeToRefs(store)
const informations = computed(() => formatProgramInformation(program.value))
</script>
