
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useEcts = defineStore<Name, State, Getters, Actions>('Ects.useEcts', {
  actions: {
    ...actions
  },

  getters: {
    // use storeToRefs
  },

  state () {
    return {
      program: null,
      course: null
    }
  }
})
