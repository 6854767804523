<template>
  <Card>
    <template #content>
      <div>
        <ResultDescription :result="result" />

        <!-- Progress bars -->
        <div class="d-flex flex-column gap-3">
          <div
            v-for="(item) in result.statistics"
            :key="item.title"
          >
            <Tag
              :value="item.percent + '%'"
              class="me-2"
            />
            <strong>{{ item.title }} </strong>

            <ProgressBar
              :value="item.percent"
              :show-value="false"
              class="mt-2"
            />
          </div>

          <div class="d-flex gap-5 flex-row-reverse">
            <button
              v-if="result.personal_answers.length"
              class="btn-primary btn"
              @click="generateFile"
            >
              {{ $t('domains.survey.question.download-personal-answers') }}
            </button>
            <button
              v-if="result.personal_answers.length"
              class="btn btn-info w-200"
              @click="showPersonalAnswersDialog()"
            >
              {{ $t("domains.survey.question.show-personal-answers-button") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import ProgressBar from 'primevue/progressbar'
import { ServiceQuestionaryResultsGetResponse } from '@/domains/ServicesSurveys/store/api/methods/getResults/types'
import { PropType } from 'vue'
import ResultDescription from './ui/ResultDescription/ResultDescription.vue'
import Card from 'primevue/card'
import Tag from 'primevue/tag'
import { focusedResult, isShowPersonalAnswersDialog } from '../../consts'

const props = defineProps({
  result: {
    type: Object as PropType<ServiceQuestionaryResultsGetResponse[0]>,
    required: true
  }
})

const decodeUnicode = (text: string) => {
  return JSON.parse(`"${text}"`)
}

const showPersonalAnswersDialog = () => {
  isShowPersonalAnswersDialog.value = true
  focusedResult.value = props.result
}

// Функция для обработки строк
const processString = (str: string) => {
  // Проверяем, содержит ли строка escape-последовательности
  if (str.startsWith('\\u')) {
    return decodeUnicode(str)
  }
  return str // Возвращаем строку как есть, если она нормальная
}

const generateFile = async () => {
  const processedStrings = props.result.personal_answers.map(processString).join('\n') // Обрабатываем строки и объединяем их в одну
  const blob = new Blob([processedStrings], { type: 'text/plain' }) // Создаем Blob для текста
  const url = URL.createObjectURL(blob) // Создаем URL для Blob

  const link = document.createElement('a') // Создаем ссылку
  link.href = url
  link.download = `${props.result.title}.csv` // Имя файла
  document.body.appendChild(link) // Добавляем ссылку в DOM
  link.click() // Симулируем клик по ссылке
  document.body.removeChild(link) // Удаляем ссылку из DOM
  URL.revokeObjectURL(url) // Освобождаем память
}
</script>
