<template>
  <pms-page-shimmer
    v-if="loader"
    shimmer="list"
    :full-width="true"
  />
  <div
    v-else
    class="border w-100 border-2 border-secondary rounded p-4 d-flex flex-column"
  >
    <h3 class="d-flex align-self-center">
      {{ $t('domains.ects.course_details.learning_outcomes') }}
    </h3>
    <pms-no-data v-if="!course?.learning_outcomes.length" />
    <ul>
      <li
        v-for="outcome in course?.learning_outcomes"
        :key="outcome"
        class="p-2 fs-5 gap-2"
      >
        <span class="fw-bold">{{ outcome.subject }}</span>
        <span>({{ outcome.outcome }})</span>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'
import { useLoader } from '@/shared/composable'

const store = useEcts()
const { isActive } = useLoader()

const { course } = storeToRefs(store)
const loader = isActive('/ects-catalogue/program/course')
</script>
