<template>
  <pms-card
    :title="null"
    :description="$t('domains.services.advisor-assignment.assign-tool.description')"
    class="mt-5"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #selected_students>
        <p v-if="students?.some(i => i._selected)">
          {{ $t('common.selected') }}
        </p>
        <div>
          <Chip
            v-for="(student) in students?.filter(i => i._selected)"
            :key="student.id"
            v-tooltip.top="student.full_name"
            :label="student.id"
            removable
            @remove="() => handleUnselectStudent(student.id)"
          />
        </div>
      </template>
      <template #formControls>
        <div class="mt-3 d-flex justify-content-start">
          <pms-button
            v-if="isSelectedStudents || isStudentIdsNotEmpty"
            type="submit"
            class="mt-2"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.assign')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import useAssignForm from './composable/useAssignForm'
import Chip from 'primevue/chip'

function handleUnselectStudent (id: string) {
  if (!students.value) return
  const student = students.value.find(i => i.id === id)
  if (!student) return
  student._selected = false
}

const { formFields, onSubmit, students, loader, isSelectedStudents, isStudentIdsNotEmpty, onHandleChange } = useAssignForm()
</script>
