import { SmartTableHeader } from '@/shared/types'

export const coursesTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.course_code',
    field: 'code'
  },
  {
    title: 'common.table.course_title',
    field: 'title'
  },
  {
    title: 'common.table.credits',
    field: 'credits'
  }
]
