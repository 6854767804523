import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'

const store = useEcts()
const { program } = storeToRefs(store)

type ElectiveCoursesArr = {
    code: string
    title: string
}

export const frormatElectiveCourses = (titles: string): ElectiveCoursesArr[] => {
  const result: ElectiveCoursesArr[] = []
  program.value?.elective_courses.forEach((course) => {
    if (titles.includes(course.code)) {
      result.push({
        code: course.code,
        title: course.title
      })
    }
  })

  return result
}
