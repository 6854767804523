import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { AcademicSingleStudentTranscriptGetResponse } from './types'

export default async function getAcademicSingleStudentTranscript (id: string): Promise<AxiosResponse<AcademicSingleStudentTranscriptGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentTranscriptGetResponse>({
    url: `academic/students/${id}/transcript`,
    body: {},
    loader: 'academic/students/transcript'
  })

  return response
}
