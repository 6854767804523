import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'empty',
  path: '/ects',
  children: [
    {
      path: ':cipher',
      name: 'EctsCatalog',
      component: () => import('./features/ProgramPage/ProgramPage.vue'),
      beforeEnter: []
    },
    {
      path: 'course/:id',
      name: 'CourseDetails',
      component: () => import('./features/CourseDetails/CourseDetails.vue'),
      beforeEnter: []
    }
  ]
} as RouteRecordWithLayout
