<template>
  <div
    class="w-100 d-flex flex-column align-items-center p-5"
    style="min-height: 100vh; overflow-y: auto;"
  >
    <HeaderButtons />
    <pms-card class="w-75">
      <template #header>
        <div class="d-flex flex-row p-4 w-100 align-items-center">
          <button
            title="Back"
            type="button"
            class="btn btn-light-primary btn-sm p-3 ps-4 pe-4"
            @click="router.back()"
          >
            <pms-kt-icon
              icon-name="arrow-left"
              class="m-0 p-0"
            />
          </button>
          <div
            v-if="course?.title"
            class="d-flex justify-content-center col-11"
          >
            <div class="flex-row flex-wrap bg-primary text-white gap-5 align-items-center justify-content-center p-4 card">
              <span
                class="fs-1 fw-bold"
              >
                {{ course.title }}
              </span>
              <span
                v-if="course?.ects"
                class="fs-2 fw-bold"
              >
                {{ $t('domains.ects.course_details.ects') }}: {{ course.ects }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <pms-row class="align-items-center justify-content-center gap-5">
        <CourseDetailTable class="col-11" />
        <CourseDescriptionCard />
        <CourseLearningOutcomesCard />
      </pms-row>
    </pms-card>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useEcts } from '../../store'
import CourseDetailTable from './ui/CourseDetailTable.vue'
import CourseDescriptionCard from './ui/CourseDescriptionCard.vue'
import CourseLearningOutcomesCard from './ui/CourseLearningOutcomesCard.vue'
import { useRoute, useRouter } from 'vue-router'
import HeaderButtons from '../../ui/HeaderButtons.vue'

const store = useEcts()
const route = useRoute()
const router = useRouter()
const courseCode = route.params.id
const { course } = storeToRefs(store)

onMounted(async () => {
  store.fetchCourse({ course_code: courseCode.toString() })
})
</script>
