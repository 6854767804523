<template>
  <div class="border border-2 border-secondary rounded gap-4 p-7 d-flex align-items-center">
    <i
      class="fa-solid fa-circle-user"
      :style="`font-size: ${device === 'mobile' ? '25' : '45'}px`"
    />
    <div class="d-flex flex-column">
      <span :class="['text-dark fw-bold', device === 'mobile' ? 'fs-6' : 'fs-3']">
        {{ coordinator.full_name }}
      </span>
      <span :class="['fw-bold', device === 'mobile' ? 'fs-7' : 'fs-5']">
        {{ coordinator.position.split('-')[0] }}
      </span>
      <span :class="[device === 'mobile' ? 'fs-7' : 'fs-5']">
        {{ coordinator.email }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue'
import { deviceType } from '@/shared/utils'
import { EctsGetProgramResponse } from '@/domains/EctsCatalog/store/api/methods/getProgram/types'

const device = deviceType()

const props = defineProps<{
  coordinator: EctsGetProgramResponse['coordinators'][0]
}>()
</script>
