<template>
  <div class="d-flex">
    <pms-page-shimmer
      v-if="loader"
      shimmer="table"
      :full-width="true"
    />
    <table
      v-else
      class="w-100 table table-bordered border-secondary"
    >
      <tbody>
        <tr
          v-for="field in courseTable"
          :key="field.title"
        >
          <th class="fs-5">
            {{ $t(field.title) }}
          </th>
          <th class="fs-5 fw-medium">
            {{ field.field }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup lang="ts">
import { useEcts } from '@/domains/EctsCatalog/store'
import { useLoader } from '@/shared/composable'
import { storeToRefs } from 'pinia'
import { formatCourseTable } from '../utils/formatCourseTable'
import { computed } from 'vue'

const { isActive } = useLoader()
const store = useEcts()

const { course } = storeToRefs(store)

const courseTable = computed(() => formatCourseTable(course.value))
const loader = isActive('/ects-catalogue/program/course')
</script>
