<template>
  <pms-card
    v-if="results !== null || loader"
    :title="$t('domains.survey.table.title')"
  >
    <div class="d-flex flex-column gap-4">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'card'"
      />
      <template
        v-for="(result) in results"
        v-else
        :key="result.question_id"
      >
        <QuestionResult
          :result="result"
        />
      </template>

      <Dialog
        v-model:visible="isShowPersonalAnswersDialog"
        maximizable
        modal
        :header="focusedResult?.title"
        class="w-75"
      >
        <p
          v-for="(personal_answer, index) in focusedResult?.personal_answers"
          :key="index"
          class="m-0"
        >
          {{ index + 1 }}.  {{ personal_answer }}
        </p>
      </Dialog>
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { useServiceSurveys } from '@/domains/ServicesSurveys/store'
import { storeToRefs } from 'pinia'
import QuestionResult from './ui/QuestionResult/QuestionResult.vue'
import { useLoader } from '@/shared/composable'
import { focusedResult, isShowPersonalAnswersDialog } from './consts'
import Dialog from 'primevue/dialog'

const store = useServiceSurveys()
const { results } = storeToRefs(store)
const { isActive } = useLoader()

const loader = isActive('get-service/questionaries/results')
</script>
