<template>
  <div class="table-responsive fixed-size mt-3">
    <p><strong>{{ $t("common.count") }}:</strong> {{ report_data.length }}</p>
    <table class="table table-bordered table-hover table-striped border-primary">
      <thead>
        <tr
          class="fixed-header"
          :class="theme === 'light' ? 'light-background' : 'dark-background'"
        >
          <td
            class="p-4"
          >
            #
          </td>
          <td
            v-for="(header,index) in headers"
            :key="index"
            class="p-4"
          >
            {{ header.field.toUpperCase() }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data,index) in report_data"
          :key="index"
        >
          <td>
            {{ index+1 }}
          </td>
          <td
            v-for="(header,i) in headers"
            :key="i"
          >
            {{ data[header.field] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useAcademicReports } from '@/domains/AcademicReports/store'
import { useThemeStore } from '@/shared/stores/pattern'
const { report_data } = storeToRefs(useAcademicReports())

const headers = computed(() => (report_data.value.length > 0 ? Object.keys(report_data.value[0]) : []).map(data => ({
  title: data,
  field: data
})))

const { theme } = storeToRefs(useThemeStore())

</script>
<style scoped>
.fixed-size{
  height:600px;
  overflow:auto
}
.fixed-header{
  position:sticky;
  top: 0;
}
</style>
