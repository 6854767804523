import { EctsGetProgramCourseResponse } from '@/domains/EctsCatalog/store/api/methods/getCourse/types'
import { Nullable } from '@/shared/types'
import { ref } from 'vue'

type CourseTable = {
    title: string;
    field: string;
}

export const formatCourseTable = (course: Nullable<EctsGetProgramCourseResponse>): CourseTable[] => {
  const data = ref<CourseTable[]>([])

  data.value.push(
    {
      title: 'domains.ects.course_details.table.year_term',
      field: `${course?.year} ${course?.term ? `(${course?.term}) ` : ''}`
    },
    {
      title: 'domains.ects.course_details.table.course_code',
      field: `${course?.course_code}`
    },
    {
      title: 'domains.ects.course_details.table.faculty',
      field: `${course?.faculty.title} `
    },
    {
      title: 'domains.ects.course_details.table.language',
      field: `${course?.language.title} (${course?.language.code})`
    }
  )

  return data.value
}
