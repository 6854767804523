import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useServiceSurveys } from './store'
import { SurveyPageNames } from './enums'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useServiceSurveys()
  store.$reset()

  store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/surveys',
  children: [
    {
      path: '',
      name: SurveyPageNames.Surveys,
      component: () => import('./ServicesSurveys.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: ':id',
      name: SurveyPageNames.Survey,
      component: () => import('./features/SurveyPage/SurveyPage.vue'),
      beforeEnter: [

      ]
    }
  ]
} as RouteRecordWithLayout
