type FormattedProgramInformation = {
    index: number;
    title: string;
    information: string | number;
    icon: string;
}

export const formatProgramInformation = (program): FormattedProgramInformation[] => {
  const res: FormattedProgramInformation[] = []
  res.push(
    {
      index: 0,
      title: 'degree',
      information: program?.degree,
      icon: 'fa-solid fa-graduation-cap'
    },
    {
      index: 1,
      title: 'program-length',
      information: `${program?.program_length}`,
      icon: 'fa-solid fa-layer-group'
    },
    {
      index: 3,
      title: 'treshold-score-for-state-scholarship',
      information: program?.threshold_state,
      icon: 'fa-solid fa-building-columns'
    },
    {
      index: 2,
      title: 'treshold-score-for-paid-department',
      information: program?.threshold_paid,
      icon: 'fa-solid fa-school'
    },
    {
      index: 4,
      title: 'ects',
      information: program?.ects_count,
      icon: 'fa-solid fa-chalkboard-user'
    },
    {
      index: 5,
      title: 'level-of-english',
      information: `${program?.english_level ? `${program.english_level.code} (${program?.english_level?.title})` : ''}`,
      icon: 'fa-sharp fa-solid fa-language'
    }
  )

  return res
}
