import getProgram from '../api/methods/getProgram'
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { EctsGetProgramRequest } from '../api/methods/getProgram/types'

export default async function fetchProgram (this: StoreType, params: EctsGetProgramRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getProgram(params)

  if (ok) {
    this.program = data
  }

  return {
    status: ok,
    message
  }
}
