<template>
  <pms-page-toolbar
    :title="title"
    :go-back="{name: SurveyPageNames.Surveys}"
  >
    <template #action>
      <pms-feedback-modal :options="[]" />
    </template>
  </pms-page-toolbar>
</template>
<script lang="ts" setup>
import { useServiceSurveys } from '@/domains/ServicesSurveys/store'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { SurveyPageNames } from '@domains/ServicesSurveys/enums'

const store = useServiceSurveys()
const { survey } = storeToRefs(store)

const title = computed(() => {
  return survey.value ? `${survey.value?.title} [${survey.value?.year} - ${survey.value?.term}]` : ''
})
</script>
