import { useServiceEsignStore } from '../store'
import { useAlert } from '@/shared/composable'
import { ServiceEsignGetResponse, ServiceEsignPutRequest } from '@/shared/swagger'
import { computed, ref } from 'vue'
import { modal_shown } from '../values'
import { getBase64, showFile } from '@/shared/utils'

const requestDefaultValue: ServiceEsignPutRequest & {
  password: string,
  item?: ServiceEsignGetResponse['signature_documents'][0]
} = {
  document_order_ids: [],
  encoded_p12_file: '',
  password: ''
}

export const sign_document_request = ref({
  ...requestDefaultValue
})

export function useSearchList () {
  const store = useServiceEsignStore()
  const { success, error } = useAlert()

  const contains_signed_documents = computed(() => sign_document_request.value.document_order_ids.some(req_val => store.esign_students.signature_documents.some(doc => doc.is_signed && doc.document_order_id === req_val)))

  async function showPDF (item:ServiceEsignGetResponse['signature_documents'][0]) {
    const blob_file = await store.fetchEsignDocument({
      documentOrder: item.document_order_id
    })
    showFile(blob_file)
  }

  async function onFileChange (event:Event) {
    const files = (event.target as HTMLInputElement).files
    if (files && files.length) {
      sign_document_request.value.encoded_p12_file = await getBase64(files[0])
    }
  }

  async function signDocument () {
    const request:ServiceEsignPutRequest & {
      password?: string,
      item?: ServiceEsignGetResponse['signature_documents'][0]
    } = JSON.parse(JSON.stringify(sign_document_request.value))

    if (sign_document_request.value.item) {
      request.document_order_ids = [sign_document_request.value.item.document_order_id]
      delete request.item
    }

    if (!request.password?.trim().length) {
      error({ text: 'Password not given' })
    }

    request.encoded_p12_file = request.encoded_p12_file.substring(request.encoded_p12_file.indexOf(',') + 1)
    const { status, message } = await store.putSign(request)
    if (status) {
      success({ text: message, isToast: true })
      closeSignModal()
      sign_document_request.value = requestDefaultValue
    } else {
      error({
        text: message,
        isToast: true,
        toastOptions: {
          life: undefined
        }
      })
    }
  }

  function openSignModal () {
    modal_shown.value = true
  }
  function closeSignModal () {
    modal_shown.value = false
  }

  function signSingleStudent (item:ServiceEsignGetResponse['signature_documents'][0]) {
    sign_document_request.value.item = item
    openSignModal()
  }

  function signAllStudents () {
    delete sign_document_request.value.item
    openSignModal()
  }

  // checkbox
  const isAllChecked = computed(() => store.esign_students.signature_documents.every(student => sign_document_request.value.document_order_ids.includes(student.document_order_id)))

  function toggleAllCheckboxOn () {
    sign_document_request.value.document_order_ids = store.esign_students.signature_documents.map(student => student.document_order_id)
  }

  function toggleAllCheckboxOff () {
    sign_document_request.value.document_order_ids = []
  }

  function toggleSingleCheckboxOn (item:ServiceEsignGetResponse['signature_documents'][0]) {
    sign_document_request.value.document_order_ids.push(item.document_order_id)
  }

  function toggleSingleCheckboxOff (item:ServiceEsignGetResponse['signature_documents'][0]) {
    sign_document_request.value.document_order_ids = sign_document_request.value.document_order_ids.filter(id => id !== item.document_order_id)
  }

  return {
    contains_signed_documents,

    showPDF,
    signDocument,
    openSignModal,
    closeSignModal,
    onFileChange,
    signSingleStudent,
    signAllStudents,

    isAllChecked,
    toggleAllCheckboxOn,
    toggleAllCheckboxOff,
    toggleSingleCheckboxOn,
    toggleSingleCheckboxOff
  }
}
