import type { AxiosResponse } from '@shared/types'
import { useRequests } from '@/shared/composable'
import { UnknownRequest } from '@/shared/swagger'
import { EctsGetProgramCourseRequest, EctsGetProgramCourseResponse } from './types'

/**
 * API /1.0.0/ects-catalogue/program/course
 * @param void
 * @returns EctsGetProgramCourseResponse
 */
export default async function getCourse (params: EctsGetProgramCourseRequest): Promise<AxiosResponse<EctsGetProgramCourseResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, EctsGetProgramCourseResponse>({
    url: '/ects-catalogue/program/course',
    body: { params },
    loader: '/ects-catalogue/program/course',
    headers: { Authorization: '' }
  })

  return response
}
