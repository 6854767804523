import { useAlert, usePagination } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { FormGroup, SelectField } from '@/shared/types'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAdvisorAssignment } from '@/domains/AdvisorAssignment/store'
import { storeToRefs } from 'pinia'
import { TreeNode } from 'primevue/treenode'

export function useAdvisorAssignmentSearchForm () {
  const { t } = useI18n()
  const { error } = useAlert()
  const { perPage, currentPage, setCurrentPage } = usePagination()
  const resource = useResource()
  const store = useAdvisorAssignment()
  const { currentYearTerm } = storeToRefs(store)

  onMounted(() => {
    resource.fetchServiceAdvisorAssignmentResource({ modules: ['programs', 'instructors', 'years', 'citizenship'] })
  })

  const treeOptions = computed(() => {
    if (!resource.serviceAdvisorAssignmentResource) return []

    const departments: Record<string, TreeNode> = {}
    resource.serviceAdvisorAssignmentResource.programs.forEach((program) => {
      const label = `${program.title} [${program.prog_code}]`
      if (!departments[program.department.title]) {
        departments[program.department.title] = {
          key: program.department.code,
          label: `${program.department.title} (${program.department.code})`,
          selectable: false,

          data: program,
          children: [
            {
              key: `${program.prog_code}`,
              label,
              data: program
            }
          ]
        }
      } else {
        departments[program.department.title].children?.push({
          key: `${program.prog_code}`,
          label,
          data: program
        })
      }
    })
    return Object.values(departments)
  })

  const citizenshipOptions = computed(() => {
    let options: SelectField['options'] = []
    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.citizenship.map((item) => {
        return {
          label: item.title,
          value: item.country_code
        }
      })
    }

    return options.sort((a, b) => a.label.localeCompare(b.label))
  })

  const yearOptions = computed(() => {
    let options: SelectField['options'] = []
    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.years.map((item) => {
        return {
          label: item.year.toString(),
          value: item.year
        }
      })
    }

    return options
  })

  const advisorOptions = computed(() => {
    let options: SelectField['options'] = []
    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.advisors.map((item) => {
        return {
          label: item.full_name,
          value: item.id
        }
      })
    }

    return options.sort((a, b) => a.label.localeCompare(b.label))
  })

  const classesOptions = computed(() => {
    let options: SelectField['options'] = []

    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.classes.map((item) => {
        return {
          label: item.toString(),
          value: item
        }
      })
    }

    return options
  })

  const tempValues = ref<
  {program_code?: string} & Record<string, any>>({
    program_code: undefined,
    entrance_year: undefined,
    class: undefined,
    employee_id: undefined,
    without_advisor: false,
    citizenship: undefined,
    student_id: undefined,
    name: undefined,
    surname: undefined,
    todo_: undefined
  })

  const selectedProgramCode = ref<Record<string, boolean> | undefined>()

  watch(selectedProgramCode, (newValue) => {
    if (!newValue) return
    const [key] = Object.entries(newValue)
    if (!key) {
      tempValues.value.program_code = undefined
      return
    }

    tempValues.value.program_code = key[0]
  })

  const formFields = computed(():Array<FormGroup> => [
    // Program
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-8'
          },
          name: 'program_code',
          type: 'slot',
          value: tempValues.value.program_code
        },

        // Entrance year
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.entrance-year')}:`,
            class: 'mb-1'
          },
          name: 'entrance_year',
          type: 'select',
          value: tempValues.value.entrance_year,
          options: yearOptions.value
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        // Student ID
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.table.student_id')}:`,
            class: 'mb-1'
          },
          name: 'student_id',
          type: 'number',
          value: tempValues.value.student_id
        },
        // Name
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.table.name')}:`,
            class: 'mb-1'
          },
          name: 'name',
          type: 'text',
          value: tempValues.value.name
        },
        // Surname
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.table.surname')}:`,
            class: 'mb-1'
          },
          name: 'surname',
          type: 'text',
          value: tempValues.value.surname
        },

        // citizenship
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.citizenship')}:`,
            class: 'mb-1'
          },
          name: 'citizenship',
          type: 'select',
          options: citizenshipOptions.value,
          value: tempValues.value.citizenship
        },

        // Class
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.common.table.class')}:`,
            class: 'mb-1'
          },
          name: 'class',
          type: 'select',
          options: classesOptions.value,
          value: tempValues.value.class
        },

        // Advisor
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.form.advisor')}:`,
            class: 'mb-1'
          },
          name: 'employee_id',
          type: 'select',
          options: advisorOptions.value,
          value: tempValues.value.employee_id
        },

        // 'Show students without advisor, ONLY'
        {
          group: {
            class: 'col-12 col-md-4 mt-3'
          },
          label: {
            text: `${t('domains.services.advisor_assignment.search.students_without_advisor')}:`,
            class: 'mb-1'
          },
          name: 'without_advisor',
          type: 'switch',
          value: tempValues.value.without_advisor || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('domains.services.advisor_assignment.search.students_without_advisor') },
              'trim'
            ]
          }
        }
      ]
    }
  ])

  const onSubmit = async ({ values }) => {
    tempValues.value = { ...values, program_code: tempValues.value.program_code }

    setCurrentPage(1)
    values.code = values.code ? values.code.toUpperCase() : undefined
    const required_fields = ['student_id', 'program_code', 'entrance_year', 'class', 'employee_id', 'name', 'surname']
    const has_one_required_field_filled = required_fields.some(key => !!tempValues.value[key])

    if (!has_one_required_field_filled) {
      error({ text: 'domains.services.advisor_assignment.search.at_least_on_of_required_fields' })
    } else {
      const { status, message } = await store.fetchStudents({
        per_page: perPage.value,
        offset: currentPage.value,
        program_code: tempValues.value.program_code,
        entrance_year: tempValues.value.entrance_year,
        class: tempValues.value.class,
        employee_id: tempValues.value.employee_id,
        without_advisor: tempValues.value.without_advisor,
        citizenship: tempValues.value.citizenship,
        student_id: tempValues.value.student_id,
        year: currentYearTerm.value.year,
        term: currentYearTerm.value.term,
        name: tempValues.value.name,
        surname: tempValues.value.surname
      })
      if (!status) error({ text: message })
    }
  }

  return {
    formFields,
    onSubmit,
    treeOptions,
    selectedProgramCode
  }
}
