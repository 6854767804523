import { useRoute } from 'vue-router'
import { defineComponent, nextTick, onBeforeMount, onMounted, watch } from 'vue'

import { useUser } from '@shared/stores'
import { useToken } from '@shared/composable'
import { useThemeStore } from '@shared/stores/pattern'
import { reinitializeComponents, LayoutUtils } from '@shared-utils/pattern'
import { PmsContent, PmsFooter, PmsDrawers, PmsScrollTop, PmsSidebar, PmsHeader } from './default'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    PmsContent,
    PmsFooter,
    PmsDrawers,
    PmsScrollTop,
    PmsSidebar,
    PmsHeader
  },
  setup () {
    const { isExpired, clearTokens } = useToken()
    const route = useRoute()
    const user = useUser()
    const theme = useThemeStore()

    if (isExpired()) {
      clearTokens()
    }

    if (user.user == null) {
      user.authMe()
    }

    onBeforeMount(() => {
      LayoutUtils.init()
      theme.setTheme(theme.theme)
    })

    onMounted(() => {
      nextTick(() => reinitializeComponents())
    })

    watch(
      () => route.path,
      () => { nextTick(() => reinitializeComponents()) }
    )

    const openOldVersion = () => {
      window.open(process.env.APP_OLD_PMS_URI, '_blank')
    }

    return () => (
      <>
        <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              <pms-header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <pms-sidebar />
                <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div class="d-flex flex-column flex-column-fluid">
                    <div id="pms-toolbar"></div>
                    <div id="kt_app_content" class="app-content flex-column-fluid">
                      <div id="pageHeaderToolbar"></div>
                      <pms-content />
                    </div>
                  </div>
                  <pms-footer />
                </div>
              </div>
          </div>
        </div>
        <pms-drawers />
        <pms-scroll-top />
        <pms-button
          class="btn-fixed text-white btn px-0 pb-4"
          color="danger"
          text="common.old-version"
          onClick={openOldVersion}
        />
      </>
    )
  }
})
