<template>
  <div class="d-flex gap-5">
    <print-action-buttons />
    <div class="btn-group">
      <template v-if="!selectedAssessment">
        <pms-button
          :size="'sm'"
          :color="'secondary'"
          :disabled="true"
          class="px-3"
        >
          <pms-kt-icon
            :icon-name="'arrow-up'"
            class="m-0 fs-4 p-0"
          />
          {{ $t('common.upload-excel') }}
        </pms-button>
      </template>
      <template v-else>
        <label
          class="btn btn-light-primary btn-sm px-4"
          for="upload-excel"
        >
          <pms-kt-icon
            :icon-name="'arrow-up'"
            class="m-0 fs-4 p-0"
          />
          {{ $t('common.upload-excel') }}
        </label>
        <input
          id="upload-excel"
          type="file"
          accept=".xlsx, .xls"
          class="d-none"
          @change="uploadExcel"
        >
      </template>
      <pms-button
        :title="'common.download-template'"
        :size="'sm'"
        class="px-3"
        @click="downloadSample"
      >
        <pms-kt-icon
          :icon-name="'file-down'"
          class="m-0 fs-4 p-0"
        />
        {{ $t('common.download') }}
      </pms-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { read, utils, writeFile } from 'xlsx'
import { storeToRefs } from 'pinia'
import { useAlert } from '@shared/composable'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { addedGrades, isEdited, selectedAssessment } from '../values'

import PrintActionButtons from './PrintActionButtons.vue'

const store = useSingleSection()
const { grades } = storeToRefs(store)

const props = defineProps({
  studentIds: {
    type: Array as PropType<Array<string>>,
    required: true
  }
})

const { error } = useAlert()

const uploadExcel = (event) => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = (e) => {
      let workbook
      const data = e.target?.result

      try {
        workbook = read(data, { type: 'binary' })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        error({
          title: 'common.error',
          text: 'common.file.wrong-file-selected',
          textProperties: { message: e.message }
        })
        return
      }

      const firstSheet = workbook.SheetNames[0]
      const excelRows = workbook.Sheets[firstSheet]

      const rows = Object.keys(excelRows).filter((key) => key.includes('A'))
      const gradeRows = Object.keys(excelRows).filter((key) => key.includes('E'))

      for (const index in rows) {
        const row = rows[index]
        const gradeRow = gradeRows[index]
        const studentId = excelRows[row].v
        const grade = +excelRows[gradeRow].v
        if (props.studentIds.includes(`${excelRows[row].v}`)) {
          if (addedGrades.value.assessment_id === selectedAssessment.value) {
            addedGrades.value = {
              ...addedGrades.value,
              grades: addedGrades.value.grades.map((g) => {
                if (g.student_id === studentId) {
                  if (grade === -1) g.absent = true
                  else g.grade = grade
                }
                return g
              })
            }
          }
        }
      }
      isEdited.value = true
    }
  } else {
    error({
      title: 'common.error',
      text: 'common.file.no-file-selected'
    })
  }
}

const downloadSample = () => {
  const wb = utils.book_new()
  const wsData = grades.value?.map((student) => [
    `${student.student_id}`,
    `${student.student_name} ${student.student_surname}`,
    `${student.speciality.title} (${student.speciality.code})`,
    `${student.absence_rate}%`
  ])

  const ws = utils.aoa_to_sheet([['STUDENT ID', 'FULL NAME', 'SPECIALITY', 'ABSENCE RATE', 'GRADE'], ...wsData])
  ws['!cols'] = [{ wch: 15 }, { wch: 25 }, { wch: 40 }, { wch: 15 }]

  utils.book_append_sheet(wb, ws, 'Students')
  writeFile(wb, 'Students.xlsx')
}
</script>
