<template>
  <div class="d-flex flex-row align-self-end gap-3">
    <pms-dropdown
      placement="end"
      :show-icon="false"
    >
      <template #button>
        <div class="menu-link px-3 py-2 w-50px">
          <span
            class="menu-icon btn btn-icon btn-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
            data-kt-element="icon"
          >
            <span class="svg-icon svg-icon-3">
              <pms-kt-icon
                :icon-name="getIcon(theme)"
                icon-class="fs-2"
              />
            </span>
          </span>
        </div>
      </template>
      <template
        v-for="(item) in themes"
        :key="item.theme"
      >
        <div class="menu-item px-3 my-0">
          <a
            href="javascript:void(0)"
            class="menu-link px-3 py-2"
            :class="{ active: theme === item.theme }"
            @click="setTheme(item.theme)"
          >
            <span
              class="menu-icon"
              data-kt-element="icon"
            >
              <span class="svg-icon svg-icon-3">
                <pms-kt-icon
                  :icon-name="item.icon"
                  icon-class="fs-2"
                />
              </span>
            </span>
            <span class="menu-title">{{ $t(`layout.mode.${item.theme}`) }}</span>
          </a>
        </div>
      </template>
    </pms-dropdown>
    <pms-dropdown
      placement="end"
      :show-icon="false"
    >
      <template #button>
        <div class="menu-link px-3 py-2 w-50px">
          <span
            class="menu-icon btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px"
            data-kt-element="icon"
          >
            <span class="svg-icon svg-icon-3">
              <img
                class="w-15px h-15px rounded-1 ms-2"
                :src="currentLocale.flag"
                :alt="currentLocale.locale"
              >
            </span>
          </span>
        </div>
      </template>
      <template
        v-for="(localeItem, index) in locales"
        :key="index"
      >
        <div class="menu-item px-3">
          <a
            href="javascript:void(0)"
            class="menu-link d-flex px-5"
            :class="{ active: locale === localeItem.short }"
            @click="setLocale(localeItem.short)"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="localeItem.flag"
                :alt="localeItem.locale"
              >
            </span>
            {{ localeItem.locale }}
          </a>
        </div>
      </template>
    </pms-dropdown>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useConfigStore, useThemeStore } from '@shared-stores/pattern'
import { useLocale } from '@shared-composable/index'

const { locale, locales, setLocale } = useLocale()
const currentLocale = computed(() => locales.value.find((item) => item.short === locale.value) || locales.value[0])
const storeTheme = useThemeStore()
const storeConfig = useConfigStore()

const theme = computed(() => storeTheme.theme)
const themes = computed(() => storeTheme.themes)

const getIcon = (theme: 'light' | 'dark' | 'system') => {
  return themes.value.find((item) => item.theme === theme)?.icon
}

const setTheme = (mode: 'dark' | 'light' | 'system') => {
  const configMode = mode
  storeConfig.setLayoutConfigProperty('general.mode', configMode)
  storeTheme.setTheme(configMode)
}
</script>
