<template>
  <Card>
    <template #title>
      <h3 class="card-title">
        {{ t('domains.academic.single-student.transcript-content.program.total.title') }} <span class="text-secondary">{{ speciality }}</span>
      </h3>
    </template>
    <template
      #content
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.program.total.total-internal-credits') }}: <span class="text-danger">{{ totalGpa.credits }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.program.total.total-credits-ects') }}: <span class="text-danger">{{ totalGpa.ects }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.program.total.grand-average') }}: <span class="text-danger">{{ totalGpa.ga }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.program.total.grand-gpa') }}: <span class="text-danger">{{ totalGpa.gpa }}</span></h5>
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup lang="ts">
import { AcademicSingleStudentTranscriptGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentTranscript/types'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'

defineProps({
  totalGpa: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['programs'][number]['total_gpa']>,
    required: true
  },
  speciality: {
    type: String,
    reqired: false
  }
})

const { t } = useI18n()
</script>
