<template>
  <div
    class="w-100 d-flex flex-column justify-content-start p-5"
    style="height: 100vh; overflow-y: auto;"
  >
    <HeaderButtons />
    <pms-page-shimmer
      v-if="loader"
      :full-width="true"
      class="m-6"
      shimmer="list"
    />
    <div
      v-else
      class="d-flex w-100 flex-column align-items-center gap-5"
    >
      <div class="d-flex card flex-column p-5 text-white bg-primary">
        <h1 class="fw-bold text-white">
          {{ program?.program_title }}
        </h1>
        <div class="d-flex fs-3 gap-2">
          {{ `${$t('domains.ects.program.education-languages')}:` }}
          <span
            v-for="language in program?.education_languages"
            :key="language"
          >
            {{ language }}
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center gap-5 col-11 flex-column flex-md-row">
        <div class="col-12 col-md-4">
          <ProgramInformation />
        </div>
        <div class="col-12 col-md-8">
          <ProgramInformationAccordion />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useEcts } from '../../store'
import { storeToRefs } from 'pinia'
import ProgramInformation from './ui/ProgramInformation.vue'
import ProgramInformationAccordion from './ui/ProgramInformationAccordion.vue'
import { useLoader } from '@/shared/composable'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import HeaderButtons from '../../ui/HeaderButtons.vue'

const route = useRoute()
const cipher = route.params.cipher
const { isActive } = useLoader()
const store = useEcts()
const { program } = storeToRefs(store)

const loader = isActive('/ects-catalogue/program')

onMounted(async () => {
  store.fetchProgram({ cipher: cipher.toString() })
})
</script>
