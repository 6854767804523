import getCourse from '../api/methods/getCourse'
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { EctsGetProgramCourseRequest } from '../api/methods/getCourse/types'

export default async function fetchCourse (this: StoreType, params: EctsGetProgramCourseRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getCourse(params)

  if (ok) {
    this.course = data
  }

  return {
    status: ok,
    message
  }
}
