<template>
  <Accordion
    class="w-100 d-flex flex-column gap-5"
    multiple
  >
    <AccordionPanel value="description">
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.program_description') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <span :class="['text-center d-flex justify-content-center', device === 'mobile' ? 'fs-7' : 'fs-4']">
          {{ program?.description ? program.description : 'No data is available' }}
        </span>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="learning_outcomes">
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.learning_outcomes') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <span :class="['text-center d-flex justify-content-center', device === 'mobile' ? 'fs-7' : 'fs-4']">
          {{ program?.learning_outcomes ? program.learning_outcomes : 'No data is available' }}
        </span>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="courses">
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.compulsory_and_elective_modules') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <ProgramCoursesTable />
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="faculty-profile">
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.faculty-profile') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <div class="d-flex flex-row justify-content-center gap-4 flex-wrap">
          <FacultyProfileCard
            v-for="coordinator in program?.coordinators"
            :key="coordinator.full_name"
            :coordinator="coordinator"
            :class="[device === 'mobile' && 'w-75']"
          />
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>
<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'
import ProgramCoursesTable from './ProgramCoursesTable.vue'
import FacultyProfileCard from './FacultyProfileCard.vue'
import { deviceType } from '@/shared/utils'

const store = useEcts()
const { program } = storeToRefs(store)
const device = deviceType()
</script>
