import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
// import type { MeResponse } from '@shared/swagger'
import { GetMeResponse } from './types'

type MeRequest = object

export default async function getUserMe (): Promise<AxiosResponse<GetMeResponse>> {
  const { get } = useRequests()

  const response = get<MeRequest, GetMeResponse>({
    url: 'me',
    body: {},
    loader: '/1.0.0/me'
  })

  return response
}
