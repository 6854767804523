<template>
  <pms-row>
    <pms-grid :col="8">
      <bell-curve
        v-if="!!addedGrades.assessment_id && currentSection.bell_curve_enabled"
        @submit="handleBellCurveSubmit"
        @revert="handleBellCurveRevert"
      />
    </pms-grid>
  </pms-row>
  <grade-statistics-component
    v-if="addedGradeExist"
    :title="bellCurvedGrades.assessment_id ? $t('domains.academic.single-section.bell-curve-statistics'): $t('domains.academic.single-section.current-statistics')"
    :grades-graph-options="gradesGraphOptions"
    :series="gradesGrapData.series"
  />
  <grade-list-header
    :grade-is-active="!selectedAssessment"
    :student-ids="studentIds"
  />
  <pms-smart-table
    id="single-course-students-table"
    :items="grades"
    :headers="lmsAssesments.length ? [
      ...gradeTableHeaders.left,
      ...tableCenterHeaders,
      ...gradeTableHeaders.right,
      ...tableEndHeaders
    ] : [
      ...gradeTableHeaders.left,
      ...tableCenterHeaders,
      ...gradeTableHeaders.right
    ]"
    :sortable="true"
    :options="{
      design: 'striped',
      wrapper: {
        responsive: true
      },
      table: {
        class: 'table-bordered table-border-blue'
      },
      headers: {
        columnStyle: 'border-color: #73b5d9 !important',
        columnClass: 'text-uppercase'
      },
      items: {
        style: 'border-bottom-width: 1px;'
      }
    }"
  >
    <template #studentInformation="{ row }">
      <grade-list-student-information
        :student="row"
      />
    </template>
    <template #speciality="{ row }">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-kt-initialized="1"
        :data-bs-original-title="row.speciality.title"
        :aria-label="row.speciality.title"
      >
        {{ row.speciality.code }}
      </span>
    </template>
    <template #absence_rate="{ row }">
      <absence-rate :rate="row.absence_rate" />
    </template>
    <template #total_grade="{ row }">
      <total-grade :grage="row.total_grade" />
    </template>
    <template #letter_grade="{ row }">
      <span class="fs-6 fw-semibold text-primary">
        {{ row.letter_grade }}
      </span>
    </template>
    <template
      v-for="(assess, index) of gradeAssessments"
      :key="index"
      #[`assess_header_${assess.assessment_id}`]="{ item }"
    >
      <template v-if="item.is_open">
        <select-assesment
          :assessment="assess"
          :grades="grades"
        />
      </template>
      <template v-else>
        <div :title="assess.assessment_title">
          <pms-kt-icon
            :icon-name="'lock'"
            class="fs-4 text-danger opacity-75"
          />
        </div>
      </template>
    </template>
    <template
      v-for="(assess, index) of gradeAssessments"
      :key="index"
      #[`assess_${assess.assessment_id}`]="{ row }"
    >
      <div class="d-flex justify-content-center">
        <template
          v-if="assess.is_open &&
            assess.assessment_id === selectedAssessment &&
            bellCurvedGrades.assessment_id &&
            getOldBellCurvedGrade(row.student_id) !== ''"
        >
          <bell-curved-grade-view
            :student-id="row.student_id"
            :assessment-id="assess.assessment_id"
          />
        </template>
        <template
          v-else-if="assess.is_open &&
            assess.assessment_id === selectedAssessment &&
            addedGrades.assessment_id &&
            !bellCurvedGrades.assessment_id"
        >
          <add-grade
            :student-id="row.student_id"
            :assessment-id="assess.assessment_id"
          />
        </template>
        <template v-else>
          <display-grade
            :student-id="row.student_id"
            :assessment-id="assess.assessment_id"
            :grades="grades"
          />
        </template>
      </div>
    </template>
    <template
      v-for="(assess, index) of lmsAssesments"
      :key="index"
      #[`lms_header_${assess.assessment_id}`]="{ item }"
    >
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-kt-initialized="1"
        :data-bs-original-title="assess.assessment_topic"
      >
        <template v-if="item.is_open">
          <select-lms-assessment
            :assessment="assess"
            :grades="grades"
          />
        </template>
        <template v-else>
          <div>
            <pms-kt-icon
              :icon-name="'lock'"
              class="fs-4 text-danger opacity-75"
            />
          </div>
        </template>
      </span>
    </template>
    <template
      v-for="(assess, index) of lmsAssesments"
      :key="index"
      #[`lms_${assess.assessment_id}`]="{ row }"
    >
      <div class="d-flex justify-content-center">
        <display-grade
          :student-id="row.student_id"
          :assessment-id="assess.assessment_id"
          :grades="grades"
        />
      </div>
    </template>
  </pms-smart-table>

  <send-form
    v-if="isEdited"
    class="mt-10"
  />
  <grade-statistics-component
    v-if="!addedGradeExist && grades.length && !!gradesGrapData.letters.length"
    :title="$t('domains.academic.single-section.average-statistics')"
    :grades-graph-options="gradesGraphOptions"
    :series="gradesGrapData.series"
  />
  <selected-students-action-button
    v-show="selectedStudentIds.length > 0"
    :student-ids="selectedStudentIds"
    :information="information || {}"
  />
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useResource, useUser } from '@shared/stores'
import { SmartTableHeader } from '@shared/types'

import GradeListStudentInformation from './GradeListStudentInformation.vue'
import GradeListHeader from './GradeListHeader.vue'
import SelectedStudentsActionButton from './SelectedStudentsActionButton.vue'
import SelectAssesment from './SelectAssesment.vue'
import AbsenceRate from './AbsenceRate.vue'
import TotalGrade from './TotalGrade.vue'
import AddGrade from './AddGrade.vue'
import DisplayGrade from './DisplayGrade.vue'
import SendForm from './SendForm.vue'
import BellCurve from './BellCurve.vue'
import BellCurvedGradeView from './BellCurvedGradeView.vue'
import GradeStatisticsComponent from './GradeStatistics.vue'
import SelectLmsAssessment from './SelectLmsAssessment.vue'

import { useSingleSection } from '../../../store'
import {
  gradeTableHeaders, selectedStudentIds, selectedAssessment,
  isEdited, bellCurvedGrades, addedGrades, clearBellCurvedGrades, getGrades, updateCategories, gradesGrapData, gradesGraphOptions, setGradesGrapData
} from '../values'
import {
  calculateBellCurve, convertLettersToGradeStatistics,
  formatGradesForGraphs, getOldBellCurvedGrade
} from '../utils'
import type { BellCurveForm } from '../types'
import { useI18n } from 'vue-i18n'
import { EventKeys } from '@/domains/SignIn/store/api/methods/getMe/enums'

const store = useSingleSection()
const resource = useResource()
const { t } = useI18n()
const userStore = useUser()
const { events } = storeToRefs(userStore)

const { currentSection, grades } = storeToRefs(store)
const information = computed(() => resource.getAcademicSingleCoursesResource?.information)
const studentIds = computed(() => grades.value.map((grade) => grade.student_id))

const gradeAssessments = computed(() => {
  const currentEvents = events.value

  const result = resource.getAcademicSingleCoursesResourceAssessments
    .filter((a) => a.section_id === currentSection.value.section_id)
    .map(a => {
      if (a.assessment_type === EventKeys.FA) {
        const event = currentEvents.find(i => i.key === EventKeys.FA)
        a.is_open = event ? event.is_open : false
      }
      if (a.assessment_type === EventKeys.FIN) {
        const event = currentEvents.find(i => i.key === EventKeys.FIN)
        a.is_open = event ? event.is_open : false
      }
      return a
    })
    .sort((a, b) => {
      const priority = {
        FA: 1, // Первый приоритет
        FIN: 2 // Второй приоритет
        // Остальные автоматически получают более низкий приоритет
      }

      // Сравнение по приоритету
      return (priority[a.assessment_type] || 99) - (priority[b.assessment_type] || 99)
    }).filter(a => a.assessment_type !== 'LMS')

  return result
}
)
const lmsAssesments = computed(() => resource.getAcademicSingleCoursesResourceAssessments
  .filter((a) => a.section_id === currentSection.value.section_id)
  .filter(a => a.assessment_type === 'LMS').map(a => ({ ...a, is_open: !!selectedAssessment.value }))
)

const tableCenterHeaders = computed<Array<SmartTableHeader>>(() => {
  const currentEvents = events.value
  return gradeAssessments.value.map((assess): SmartTableHeader => {
    const event = currentEvents.find(i => i.key === assess.assessment_type)

    return {
      title: assess.assessment_topic,
      field: `assess_parent_header_${assess.assessment_id}`,
      tooltip: assess.assessment_title,
      subHeaders: [
        {
          title: assess.assessment_type,
          field: `assess_${assess.assessment_id}`,
          slotName: `assess_header_${assess.assessment_id}`,
          slotCellName: `assess_${assess.assessment_id}`,
          headerClass: 'text-center w-100px',
          cellClass: 'text-center',
          is_open: event ? event.is_open : false,
          assessment: assess.assessment_id
        } as SmartTableHeader
      ]
    }
  })
})

const tableEndHeaders = computed<Array<SmartTableHeader>>(() => ([
  {
    title: currentSection.value.last_moodle_sync_date ? `LMS<br>(${t('domains.academic.single-section.last-sync-date')}: ${currentSection.value.last_moodle_sync_date.split(' ')[0]})` : 'RE',
    field: 'lms_header',
    subHeaders: lmsAssesments.value.map(
      (assess): SmartTableHeader => ({
        title: assess.assessment_topic,
        field: `lms_${assess.assessment_id}`,
        slotName: `lms_header_${assess.assessment_id}`,
        slotCellName: `lms_${assess.assessment_id}`,
        headerClass: 'text-center w-100px',
        cellClass: 'text-center',
        is_open: !!selectedAssessment.value,
        assessment: assess.assessment_id
      } as SmartTableHeader))
  }
]))

const handleBellCurveSubmit = (values: BellCurveForm) => {
  const calculatedGrades = calculateBellCurve(addedGrades.value, values.mean, values.deviation)
  bellCurvedGrades.value = { ...calculatedGrades }

  isEdited.value = true
}
const handleBellCurveRevert = () => {
  clearBellCurvedGrades()
  isEdited.value = false
}

const addedGradeExist = computed(() =>
  addedGrades.value.assessment_id &&
  addedGrades.value.grades.some(g => !!g.grade)
)

watch(() => [bellCurvedGrades.value, addedGrades.value, grades.value], async () => {
  if (addedGradeExist.value) {
    const gradeStatistics = await store.fetchGradeStatistics(addedGrades.value.assessment_id + '', { grades: getGrades() })

    setGradesGrapData(formatGradesForGraphs(gradeStatistics, true))
    updateCategories(gradesGrapData.value)
  } else if (grades.value.length) {
    const averageGradeStatistics = convertLettersToGradeStatistics(grades.value.map(g => g.letter_grade))

    setGradesGrapData(formatGradesForGraphs(averageGradeStatistics))
    updateCategories(gradesGrapData.value, true)
  }
}, { deep: true })
</script>
