export enum EventKeys {
    SMAN_ANSWER_REQUESTS = 'SMAN_ANSWER_REQUESTS',
    SMAN_SEND_REQUESTS = 'SMAN_SEND_REQUESTS',
    COURSE_REG_STUD = 'COURSE_REG_STUD',
    SEMESTER_GAP = 'SEMESTER_GAP',
    ADD_DROP = 'ADD_DROP',
    LATE_CR_REQUESTS = 'LATE_CR_REQUESTS',
    FA = 'FA',
    EW = 'EW',
    LW = 'LW',
    COURSE_ASSESSMENT_MODIFICATIONS = 'COURSE_ASSESSMENT_MODIFICATIONS',
    FIN = 'FIN',
    MUE_REQUESTS = 'MUE_REQUESTS',
    MUE = 'MUE'
  }
