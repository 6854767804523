import type { AxiosResponse } from '@shared/types'
import { EctsGetProgramRequest, EctsGetProgramResponse } from './types'
import { useRequests } from '@/shared/composable'
import { UnknownRequest } from '@/shared/swagger'

/**
 * API /1.0.0/...
 * @param void
 * @returns EctsGetProgramResponse
 */
export default async function getProgram (params : EctsGetProgramRequest): Promise<AxiosResponse<EctsGetProgramResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, EctsGetProgramResponse>({
    url: '/ects-catalogue/program',
    body: { params },
    loader: '/ects-catalogue/program',
    headers: { Authorization: '' }
  })

  return response
}
