<template>
  <div class="d-flex gap-2">
    <pms-button
      :title="'common.download-template'"
      :size="'sm'"
      class="px-3"
      @click="onPrintExamReport"
    >
      Exam Report
    </pms-button>

    <pms-dropdown
      v-if="gradeAssessments.length"
      :btn-class="'btn btn-light-primary btn-sm px-3 '"
      :icon-placement="'end'"
      :placement="'end'"
      :btn-text="'Participation List'"
    >
      <template
        v-for="(assesment,index) in gradeAssessments"
        :key="assesment.assessment_id"
      >
        <div
          class="menu-item w-175px"
          @click="onPrintParticipationList(assesment.assessment_id)"
        >
          <span
            class="menu-link px-3 py-2 text-center"
          >
            <span class="menu-title">{{ assesment.assessment_title }}</span>
          </span>
        </div>
        <div
          v-if="index+1 !== gradeAssessments.length"
          class="separator my-1"
        />
      </template>
    </pms-dropdown>
  </div>

  <div class="d-none">
    <div id="printExamReport">
      <print-exam-reports />
    </div>
  </div>
  <div class="d-none">
    <div id="printParticipationList">
      <print-participation-list />
    </div>
  </div>
</template>

<script setup lang="ts">
import { printExamReport, printParticipationLists } from '../utils'
import { storeToRefs } from 'pinia'

import { useSingleSection } from '@/domains/AcademicSingleSection/store'

import PrintExamReports from './PrintExamReports.vue'
import PrintParticipationList from './PrintParticipationList.vue'
import { useResource } from '@/shared/stores'
import { computed } from 'vue'

const store = useSingleSection()
const resource = useResource()

const { currentSection } = storeToRefs(store)

const gradeAssessments = computed(() => resource.getAcademicSingleCoursesResourceAssessments
  .filter((a) => a.section_id === currentSection.value.section_id)
  .sort((a) => {
    if (a.assessment_type === 'FA') return -1
    if (a.assessment_type === 'FIN') return -1
    return 1
  }).filter(a => a.assessment_type !== 'LMS')
)

const onPrintExamReport = async () => {
  await store.fetchAcademicCoursesGradesExamReports({ section_id: currentSection.value.section_id })
  const element = document.getElementById('printExamReport')

  if (!element) return
  printExamReport(element)
}

const onPrintParticipationList = async (assesment_id: number) => {
  await store.fetchAcademicCoursesGradesParticipants(assesment_id)
  const element = document.getElementById('printParticipationList')

  if (!element) return
  printParticipationLists(element)
}

</script>
