<template>
  <Card>
    <template #title>
      <h3 class="card-title">
        {{ t('domains.academic.single-student.transcript-content.overall.title') }}
      </h3>
    </template>
    <template
      #content
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.overall.total-internal-credits') }}: <span class="text-danger">{{ overallGpa.credits }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.overall.total-credits-ects') }}: <span class="text-danger">{{ overallGpa.ects }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.overall.grand-average') }}: <span class="text-danger">{{ overallGpa.ga }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-student.transcript-content.overall.grand-gpa') }}: <span class="text-danger">{{ overallGpa.gpa }}</span></h5>
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup lang="ts">
import { AcademicSingleStudentTranscriptGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentTranscript/types'
import { PropType } from 'vue'
import Card from 'primevue/card'
import { useI18n } from 'vue-i18n'

//
defineProps({
  overallGpa: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['overall_gpa']>,
    required: true
  }
})

const { t } = useI18n()

</script>
