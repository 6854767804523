<template>
  <pms-button
    :variant="'outlined'"
    :title="'edit selected'"
    class="btn w-180px"
    @click="handleShowDialog"
  >
    <i class="bi bi-pencil" />
    {{ $t('domains.academic.manage.curricula.edit-selected-electives') }}
  </pms-button>
  <Dialog
    :visible.sync="showDialog"
    :modal="true"
    :closable="false"
    :header="t('domains.academic.manage.curriculum.edit-course-settings-title')"
    @hide="handleHideDialog"
  >
    <div class="d-flex flex-column gap-3 mb-5">
      <span class="fw-bold fs-6">{{ $t('domains.academic.manage.course.course-list.title') }}:</span>
      <div class="d-flex gap-2 align-items-center flex-wrap">
        <span
          v-for="course in selectedCourses"
          :key="course.id"
          class="badge rounded-pill bg-secondary mb-1"
        >
          {{ `${course.code}: ${course.title}` }}
        </span>
      </div>
    </div>
    <pms-smart-form
      class="d-flex flex-wrap"
      :fields="[courseSettingsFormFields]"
      :options="{
        controls: {class: 'w-100 mt-5'}
      }"
      :loader="saveLoader"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <br>
        <div class="form-group mt-3 w-100 mt-2 d-flex justify-content-end gap-5">
          <pms-button
            type="button"
            :class="'btn-success'"
            :disabled="saveLoader"
            @Click="onSubmit"
          >
            {{ t('common.submit') }}
          </pms-button>
          <pms-button
            type="submit"
            :disabled="saveLoader"
            :text="$t('common.cancel')"
            @Click="handleHideDialog"
          />
        </div>
      </template>
    </pms-smart-form>
  </Dialog>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import useCoreEditor from '../composable/useCoreEditor'
import { useLoader } from '@/shared/composable'
import Dialog from 'primevue/dialog'
const { isActive } = useLoader()
const { t } = useI18n()

const props = defineProps({
  electiveType: {
    type: String,
    required: true
  }
})

const saveLoader = isActive('put-academic/manage/curriculum/core/bulk')
const { courseSettings, courseSettingsFormFields, selectedCourses, onSubmit, showDialog, handleShowDialog, handleHideDialog } = useCoreEditor(props.electiveType)

const onHandleChange = ({ name, newValue }) => {
  courseSettings.value[name] = newValue
}
</script>
<style scope>
.formItem {
  width: 200px
}
</style>
