import { useResource } from '@/shared/stores'
import { FormGroup, SelectField } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAdvisorAssignment } from '@/domains/AdvisorAssignment/store'
import { storeToRefs } from 'pinia'
import { useAlert, useLoader, usePagination } from '@/shared/composable'

export default function useAssignForm () {
  const tempValues = ref<{
    employee_id?: string,
    student_ids?: string

  }>({
    employee_id: undefined,
    student_ids: undefined
  })

  const resource = useResource()
  const { t } = useI18n()
  const store = useAdvisorAssignment()
  const { students } = storeToRefs(store)
  const { trigger } = usePagination()
  const { isActive } = useLoader()
  const { error } = useAlert()
  const isSelectedStudents = computed(() => {
    return students.value ? students.value.some(i => i._selected) : false
  })
  const isStudentIdsNotEmpty = computed(() => {
    return tempValues.value.student_ids ? tempValues.value.student_ids.trim().length > 0 : false
  })

  const loader = isActive('put-service/manage/advisors/bulk')

  const advisorOptions = computed(() => {
    let options: SelectField['options'] = []
    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.advisors.map((item) => {
        return {
          label: item.full_name,
          value: item.id
        }
      })
    }

    return options.sort((a, b) => a.label.localeCompare(b.label))
  })

  const formFields = computed(():Array<FormGroup> => [
    {
      class: 'row mb-3',
      fields: [
        // Advisor
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.form.advisor')}:`,
            class: 'mb-1'
          },
          name: 'employee_id',
          type: 'select',
          options: advisorOptions.value,
          value: tempValues.value.employee_id
        },

        {
          group: {
            class: 'col-12 col-md-8'
          },
          label: {
            text: `${t('common.table.student_id')}:`,
            class: 'mb-1'
          },
          placeholder: `${t('common.table.student_ids')}:`,
          name: 'student_ids',
          type: 'textarea',
          value: tempValues.value.student_ids
        },
        {
          group: {
            class: 'col-12 col-md-12'
          },
          label: {
            text: `${t('common.form.students')}:`,
            class: 'mb-1'
          },
          name: 'selected_students',
          type: 'slot'
        }
      ]
    }
  ])

  const onSubmit = async ({ values }) => {
    tempValues.value = { ...values }

    let studentIds: string[] = []

    try {
      studentIds = mergeUniqueArraysFromStrings(
        students.value ? students.value.filter(i => i._selected).map(i => i.id) : [],
        values.student_ids ? parseStudentIdsToNumberArray(values.student_ids) : []
      )
    } catch (e) {
      if (e instanceof Error) {
        error({
          text: e.message
        })
      }
    }

    if (!students.value || students.value.length === 0 || !tempValues.value.employee_id) return

    const result = await store.actionAssignStudentsToAdvisor({
      students_list: studentIds,
      advisor_id: tempValues.value.employee_id
    })

    if (result.status) {
      trigger()
      tempValues.value.student_ids = ''
    }
  }

  const onHandleChange = ({ name, newValue }) => {
    tempValues.value[name] = newValue
  }

  function parseStudentIdsToNumberArray (input: string): string[] {
    const array: string[] = input.split(',').map(item => item.trim()) // Разделяем строку и удаляем лишние пробелы

    for (const element of array) {
      if (!/^\d+$/.test(element)) {
        throw new Error(t('domains.services.advisor_assignment.assign-students-string-error', { element }))
      }
    }

    return array.map(String) // Преобразуем элементы в числа
  }

  function mergeUniqueArraysFromStrings (array1, array2): string[] {
    const mergedArray = [...new Set([...array1, ...array2])] // Удаляем дубликаты

    return mergedArray
  }

  return {
    formFields,
    students,
    onSubmit,
    loader,
    isSelectedStudents,
    isStudentIdsNotEmpty,
    onHandleChange
  }
}
