export default [
  'SignInPage',
  'SignOutPage',
  'ForgotPasswordPage',
  'ResetPasswordPage',

  'AboutPage',
  'PrivacyPolicyPage',
  'TermsOfServicePage',
  'SupportPage',

  'TranslateMessages',
  'TranslateMessage',
  'TranslateContents',
  'TranslateContent',
  'TranslateTours',
  'TranslateTour',
  'EctsCatalog',
  'CourseDetails'
]
