<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="back()"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <h1 class="text-dark fs-2 fw-bold mb-1">
          {{ $t('domains.academic.manage.curricula.select-program', { department: `${currentDepartment?.title} (${currentDepartment?.code})` }) }}
        </h1>
      </div>
    </div>
    <p class="fs-6 mt-3 mb-4">
      {{ $t('domains.academic.manage.curricula.select-program-description') }}
    </p>

    <pms-alert>
      <template #title>
        {{ $t('common.selected-department') }}:
        <span
          v-if="currentDepartment"
          class="text-decoration-underline fw-bold"
        >
          {{ currentDepartment.title }} ({{ currentDepartment.code }})
        </span>
      </template>
    </pms-alert>

    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="programs-table"
      :items="programs"
      :headers="headers"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #title="{ item }">
        <span class="fs-5 fw-bold">{{ item.title }}</span>
      </template>
      <template #language="{ item }">
        <span class="badge badge-light-primary me-2 mb-2">
          {{ item.education.language.title }}
        </span>
      </template>
      <template #level="{ item }">
        <span>
          {{ item.education.level.title }} ({{ item.duration_in_terms }})
        </span>
        <br>
        <span v-if="item.post_degree_type">
          - {{ item.post_degree_type.title }}
        </span>
      </template>
      <template #faculty="{ item }">
        <span>
          {{ item.faculty.title }}
        </span>
      </template>
      <template #type="{ value }">
        <span
          :title="value.title"
          class="cursor-default"
        >
          {{ value.key }}
        </span>
      </template>
      <template #action="{ item }">
        <div class="d-flex gap-5">
          <button
            class="btn btn-sm btn-light-primary w-100px"
            @click="next(item.year, item.department.code, item.code)"
          >
            {{ $t('common.select') }}
            <pms-kt-icon
              icon-name="arrow-right"
              class="ms-2 m-0 p-0"
            />
          </button>
          <!-- Скрыто по задаче PMS-T-1929 -->
          <!-- <button
            class="btn btn-sm btn-light-primary w-100px"
            @click="openOutcome({ year: item.year, code: item.code})"
          >
            {{ $t('common.common.outcome') }}
          </button> -->
        </div>
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useLoader } from '@shared/composable'

import { useStore } from '../store'
import { programsTable as headers, programStepQuickTourSteps } from '../values'
import { useRouter } from 'vue-router'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const store = useStore()
const router = useRouter()

const loader = isActive('academic/manage/curriculum/programs')
const programs = computed(() => store.programs)
const currentDepartment = computed(() => store.getDepartments.find((dep) => dep.code === store.getDepartment))

const back = () => {
  store.setCurrentStep('department')
}

const next = (year: number, departmentCode: string, programCode: string) => {
  store.setCurrentStep('track')
  store.setProgram(programCode)
  store.fetchTracks(year, departmentCode, programCode)
}

const openOutcome = (data: Pick<typeof programs['value'][0], 'year' | 'code'>) => {
  router.push({
    name: 'AcademicCurriculaOutcomePage',
    params: {
      program_code: data.code,
      program_year: data.year
    }
  })
}

onMounted(async () => {
  setQuickTourSteps(await programStepQuickTourSteps())
})
</script>
