<template>
  <Accordion
    class="d-flex gap-3 flex-column flex-xl-row"
    style="max-height: 50vh; overflow-y: auto;"
    multiple
  >
    <AccordionPanel
      class="w-100 w-xl-50"
      value="core_courses"
    >
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.courses.core') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <div
          v-for="term in program?.core_courses"
          :key="term.term"
          class="w-100"
        >
          <div class="w-100 d-flex align-items-start p-2">
            <span
              class="pinned text-muted position-relative"
              style="font-size: 8vh; opacity: 0.2;"
            >
              {{ term.term }}
            </span>
            <DataTable
              :value="term?.items"
              show-gridlines
              class="w-100"
            >
              <Column
                :header="$t('common.table.course-title')"
                filed="title"
                class="fs-5"
              >
                <template #body="{ data }">
                  <div class="d-flex gap-3 align-items-center">
                    <router-link
                      v-if="!data.code.includes('X')"
                      :class="[
                        'border d-flex justify-content-center col-2 col-xl-3 col-xxl-2 p-3 bg-transparent border-2 border-primary rounded',
                        device === 'mobile' ? 'p-3' : 'pb-3 pt-3'
                      ]"
                      :to="{
                        name: 'CourseDetails',
                        params: { id: data.code }
                      }"
                    >
                      <span
                        :class="[
                          'text-primary text-center fw-bold fs-7 fs-xxl-5 fs-xl-6',
                          device === 'mobile' ? 'fs-7' : 'fs-5'
                        ]"
                      >
                        {{ data.code }}
                      </span>
                    </router-link>
                    <div
                      v-else
                      :class="[
                        'border d-flex justify-content-center col-2 col-2 col-xl-3 col-xxl-2 bg-transparent border-2 border-primary rounded',
                        device === 'mobile' ? 'p-3' : 'pb-3 pt-3'
                      ]"
                    >
                      <span
                        class="text-primary text-center fw-bold fs-7 fs-xxl-5 fs-xl-6"
                      >
                        {{ data.code }}
                      </span>
                    </div>
                    <span
                      v-if="!data.code.includes('X')"
                      class="fw-bold fs-7 fs-xxl-5 fs-xl-6"
                    >
                      {{ data.title }}
                    </span>
                    <span
                      v-else
                      :class="['fw-bold d-flex flex-row flex-wrap gap-2', device === 'mobile' ? 'fs-7' : 'fs-4']"
                    >
                      {{ data.title.split(/[()]/)[0] }} (
                      <span
                        v-for="(course, index) in frormatElectiveCourses(data.title.split(/[()]/)[1])"
                        :key="course.code"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="course.title"
                      >
                        {{ course.code }} {{ index < frormatElectiveCourses(data.title.split(/[()]/)[1]).length - 1 ? ',' : '' }}
                      </span>
                      )
                    </span>
                  </div>
                </template>
              </Column>
              <Column
                :header="$t('common.table.ects')"
                field="ects"
                class="fs-5"
              >
                <template #body="{ data }">
                  <div class="d-flex align-items-center justify-content-center">
                    <span :class="['text-center', device === 'mobile' ? 'fs-7' : 'fs-5']">{{ data.ects }}</span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel
      class="w-100 w-xl-50"
      value="elective_courses"
    >
      <AccordionHeader>
        <span :class="[device === 'mobile' ? 'fs-7' : 'fs-4']">{{ $t('domains.ects.program.courses.elective') }}</span>
      </AccordionHeader>
      <AccordionContent>
        <div class="w-100">
          <DataTable
            :value="program?.elective_courses"
            show-gridlines
          >
            <Column
              :header="$t('common.table.course-title')"
              filed="title"
              class="fs-5"
            >
              <template #body="{ data }">
                <div class="d-flex gap-3 align-items-center">
                  <router-link
                    :class="[
                      'border d-flex justify-content-center col-2 col-xl-3 col-xxl-2 p-3 bg-transparent border-2 border-primary rounded',
                      device === 'mobile' ? 'p-3' : 'pb-3 pt-3'
                    ]"
                    :to="{
                      name: 'CourseDetails',
                      params: {
                        id: data.code
                      }
                    }"
                  >
                    <span :class="['text-primary text-center fw-bold', device === 'mobile' ? 'fs-7' : 'fs-5']">{{ data.code }}</span>
                  </router-link>
                  <span class="fw-bold fs-7 fs-xxl-5 fs-xl-6">{{ data.title }}</span>
                </div>
              </template>
            </Column>
            <Column
              :header="$t('common.table.ects')"
              field="ects"
              class="fs-5"
              sortable
            >
              <template #body="{ data }">
                <div class="d-flex align-items-center justify-content-center">
                  <span :class="['text-center', device === 'mobile' ? 'fs-7' : 'fs-5']">{{ data.ects }}</span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>
<script setup lang="ts">
import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'
import { deviceType } from '@/shared/utils'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Accordion from 'primevue/accordion'
import AccordionContent from 'primevue/accordioncontent'
import AccordionHeader from 'primevue/accordionheader'
import AccordionPanel from 'primevue/accordionpanel'
import { frormatElectiveCourses } from '../utils'

const store = useEcts()
const { program } = storeToRefs(store)
const device = deviceType()
</script>
