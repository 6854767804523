<template>
  <div
    v-if="!loader"
    class="border w-100 border-2 border-secondary rounded p-4 d-flex flex-column align-items-center"
  >
    <h3>{{ $t('domains.ects.course_details.course_description') }}</h3>
    <span
      v-if="course?.course_description"
      class="fs-5 w-100"
    >
      {{ course.course_description }}
    </span>
    <pms-no-data v-else />
  </div>
</template>
<script setup lang="ts">
import { useEcts } from '@/domains/EctsCatalog/store'
import { storeToRefs } from 'pinia'
import { useLoader } from '@/shared/composable'

const { isActive } = useLoader()
const store = useEcts()

const { course } = storeToRefs(store)
const loader = isActive('/ects-catalogue/program/course')
</script>
